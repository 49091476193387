<template>
<div class="wrap">
    <div class="mg-auto" style="width: 1250px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> B2C 지출 수정</h2>

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">지출내용 <span class="red">*</span></th>
                                <td class="left">
                                    <input v-model="expText" type="text" class="ml-20 w-200px">
                                </td>
                                <th>지출금액 <span class="red">*</span></th>
                                <td class="left">
                                    <input v-model="expPrice" type="text" class="ml-20 w-200px"> 원
                                </td>
                            </tr>
                            <tr>
                                <th>지출날짜 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <crm-datepicker class="ml-20" v-model="expensesDate" :parentDate="expensesDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                </td>
                            </tr>
                            <tr>
                                <th>사용자</th>
                                <td class="left">
                                    <input v-model="expensesName" type="text" class="ml-20 w-200px">
                                </td>
                                <th>센터선택</th>
                                <td class="left">
                                    <select v-model="idxCrmCenter" class="ml-20 w-100px">
                                        <option value="">센터선택</option>
                                        <option value="-1">본원센터</option>
                                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 111px;">메모</th>
                                <td class="left" colspan="3">
                                    <textarea v-model="expensesMemo" type="text" class="ml-20 w-98per h-100px" id="introduce" name="introduce" value="" />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="remove(idx)">삭제</a>
                        <a class="btn_cancle mr-10 pointer" @click="modify()">수정</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
import {
    EmptyCheck
} from '@/utils/validation.js'
export default {
    data: () => ({
        centerList: [],
        idxCrmCenter: -1,
        expText: '',
        expensesName: '',
        expensesCompanyName: '',
        expPrice: '',
        expensesDate: '',
        centerName: '',
        expensesMemo: '',
        expensesType: 'B2C',
        idx: -1,

    }),

    mounted() {
        this.expensesDate = this.formatDate(new Date())
        this.getCenterListAll()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getExpenses(this.idx)
    },

    methods: {

        getExpenses(idx) {
            this.axios.get(`/api/v1/user/expenses/${idx}`)
                .then(res => {
                    for(var item of Object.keys(res.data.expVO)) {
                        this[item] = res.data.expVO[item]
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 수정
        modify() {

            if (!EmptyCheck(this.expText, '지출내용을')) {
                return false
            }

            if (!EmptyCheck(this.expPrice + '', '지출금액을')) {
                return false
            }

            if (!EmptyCheck(this.formatDate(this.expensesDate), '지출일자를')) {
                return false
            }

            if (this.idxCrmCenter !== -1) {
                if (this.idxCrmCenter === "0") {
                    this.centerName = '본원센터'
                } else {
                    for (var center of this.centerList) {
                        if (this.idxCrmCenter === center.idx) {
                            this.centerName = center.name
                        }
                    }
                }
            }

            let vo = {
                expText: this.expText,
                expPrice: this.expPrice,
                expensesDate: this.formatDate(this.expensesDate),
                expensesName: this.expensesName,
                idxCrmCenter: this.idxCrmCenter,
                centerName: this.centerName,
                expensesMemo: this.expensesMemo,
                expensesCompanyName: this.expensesCompanyName
            }
            this.axios.put(`/api/v1/user/expenses/${this.idx}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert(res.data.result)
                        //this.$router.push('/origin/sales_manage_expend_b2c')
                        history.back()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        },

        /**
         * @description 삭제
         */
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/user/expenses/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.push('/origin/sales_manage_expend_b2c');
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err.response)
                        if (err.response.data.path == '/login/msg') {
                            alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                            this.$router.push('/login')
                        } else {
                            alert('관리자에게 문의하세요')
                        }
                    })
            } else {
                return false
            }
        }

    }
}
</script>
